import { lazy } from "react";
import PrintPreview from "./components/PrintPreview";
import PrintPreviewBo from "./components/PrintPreviewBo";


const Main = lazy(() => import("./containers/Products/Products"));
const Products = lazy(() => import("./containers/Products/Products"));
const CreateBaseProduct = lazy(() =>
  import("./containers/Products/CreateBaseProduct")
);
const ProductVersions = lazy(() =>
  import("./containers/Products/ProductVersions")
);
const AddUser = lazy(() => import("./containers/Users/AddUser"));
const UserDetail = lazy(() => import("./containers/Users/UserDetail"));

// cust detail
const CustomerDetail = lazy(() => import("./containers/Users/CustomerDetail"));

const Role = lazy(() => import("./containers/Roles/Roles"));
const Organizations = lazy(() =>
  import("./containers/Organizations/Organizations")
);
const PartnerApplication = lazy(() =>
  import("./containers/Partners/PartnerApplication")
);
const Loyalty = lazy(() => import("./containers/Loyalty/Loyalty"));
const LoyaltyExpiry = lazy(() => import("./containers/Loyalty/LoyaltyExpiry"));
const EditLoyaltyExpiry = lazy(() =>
  import("./containers/Loyalty/EditLoyaltyExpiry")
);
const AccessReport = lazy(() => import("./containers/Users/AccessReport"));
const AddVoucherOri = lazy(() => import("./containers/Vouchers/AddVoucherOri"));
const AddVoucher = lazy(() => import("./containers/Vouchers/AddVoucher"));
const AddNewVoucher = lazy(() => import("./containers/Vouchers/AddNewVoucher"));
const VoucherBatches = lazy(() =>
  import("./containers/Vouchers/VoucherBatches")
);

const Vouchers = lazy(() => import("./containers/Vouchers/Vouchers"));
const VoucherDetail = lazy(() => import("./containers/Vouchers/VoucherDetail"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const PartnerInbox = lazy(() => import("./containers/Partners/Inbox"));
const Users = lazy(() => import("./containers/Users/Users"));
const Customers = lazy(() => import("./containers/Users/Customers"));
const Logout = lazy(() => import("./containers/Logout/Logout"));
const VoucherCodeInfo = lazy(() =>
  import("./containers/Vouchers/voucherCodeChecker")
);
const AddLoyaltyRule = lazy(() =>
  import("./containers/Loyalty/AddLoyaltyRule")
);

const Test = lazy(() => import("./containers/TestComponents/Test"));
const EditLoyaltyRule = lazy(() =>
  import("./containers/Loyalty/EditLoyaltyRule")
);

const LoyaltyPointValue = lazy(() =>
  import("./containers/Loyalty/LoyaltyPointValue")
);
const BusinessRules = lazy(() =>
  import("./containers/BusinessRules/BusinessRules")
);
const AddProduct = lazy(() => import("./containers/Products/AddProduct"));
const UploadTemplate = lazy(() => import("./containers/NewVouchers/UploadTemplate"));
const FareStructure = lazy(() =>
  import("./containers/FareStructure/FareStructure")
);
const StToStFare = lazy(() => import("./containers/FareStructure/StToStFare"));

const UserSearch = lazy(() => import("./containers/Users/UsersSearch"));
const UserCard = lazy(() => import("./containers/Users/UserCard"));
const AddUserCard = lazy(() => import("./containers/Users/AddCard"));

const Profile = lazy(() => import("./containers/Users/profile"));

const Redemption = lazy(() => import("./containers/Users/Redemption"));
const ActiveTicket = lazy(() => import("./containers/Users/ActiveTicket"));
const ActivateTicketByOrderId = lazy(() => import("./containers/Users/ActiveTicketOrderId"));
const TicketActivationHistory = lazy(() => import("./containers/Users/TicketActivationHistory"));
const TicketDownload = lazy(() => import("./containers/Users/TicketDownload"));
const SendMessages = lazy(() => import("./containers/Users/SendMessages"));
const Revenue = lazy(() => import("./containers/report/Revenue"));
const InstanceDetails = lazy(() =>
  import("./containers/workflow/InstanceDetails")
);

const InstancePermission = lazy(() =>
  import("./containers/workflow/InstancePermission")
);

const TransitionPermission = lazy(() =>
  import("./containers/workflow/TransitionPermission")
);

const Instances = lazy(() => import("./containers/workflow/Instances"));

const IssueTicket = lazy(() => import("./containers/BackOffice/IssueTicket"));

const PurchaseTicket = lazy(() =>
  import("./containers/BackOffice/PurchaseTicket")
);

const TicketSummary = lazy(() =>
  import("./containers/BackOffice/TicketSummary")
);
const BoHistoryMaster = lazy(() =>
  import("./containers/BackOffice/HistoryMaster"),
);
const BoHistoryDetails = lazy(() =>
  import("./containers/BackOffice/HistoryDetails"),
);
const BoTrainStatus = lazy(() =>
  import("./containers/BackOffice/TrainStatus"),
);
const BoAddTrainStatus = lazy(() =>
  import("./containers/BackOffice/AddTrainStatus"),
);
const BoEditTrainStatus = lazy(() =>
  import("./containers/BackOffice/EditTrainStatus"),
);

const VouchersLanding = lazy(() =>
  import("./containers/NewVouchers/VouchersLanding")
);

const VouchersList = lazy(() =>
  import("./containers/NewVouchers/VouchersList")
);

const VouchersTemplate = lazy(() =>
  import("./containers/NewVouchers/VouchersTemplate")
);
const VoucherApproval = lazy(() =>
  import("./containers/NewVouchers/VoucherApproval")
);
const VouchersDetails = lazy(() =>
  import("./containers/NewVouchers/VoucherDetails")
);

const ErlVoucher = lazy(() =>
import("./containers/Vouchers/ERLvoucher")
);
const PartyVoucher = lazy(() =>
import ("./containers/Vouchers/PartyVoucher"));


const approvalCount=5;
export default [
  {
    title: "Reset Password(Customer)",
    path: "/users/customer",
    component: Customers,
    main: "Users",
    sub: "list",
    order: 1,
    permission: "6",
    menu: true
  },
  {
    title: "Manage Users",
    path: "/users/list",
    component: Users,
    main: "Users",
    sub: "list",
    order: 1,
    permission: "11",
    menu: true,
  },
  {
    title: "Role",
    path: "/users/role",
    component: Role,
    main: "Users",
    sub: "role",
    order: 2,
    permission: "11",
    menu: true,
  },
  {
    title: "Create Base Product",
    path: "/products/createbaseproduct",
    component: CreateBaseProduct,
    main: "Products",
    sub: "createbaseproduct",
    order: 1,
    permission: "83",
    menu: true,
  },
  {
    title: "Manage Products",
    path: "/products/products",
    component: Products,
    main: "Products",
    sub: "products",
    order: 2,
    permission: "23",
    menu: true,
  },

  {
    title: "Products",
    path: "/products/products/:id/versions",
    component: ProductVersions,
    main: "Products",
    sub: "products",
    order: 2,
    permission: "23",
    menu: false,
  },
  {
    title: "Add Product",
    path: "/products/products/add",
    component: AddProduct,
    main: "products",
    sub: "products",
    order: 2,
    permission: "60",
    menu: false,
  },
  {
    title: "Edit Product",
    path: "/products/products/edit/:id",
    component: AddProduct,
    main: "products",
    sub: "products",
    order: 2,
    permission: "60",
    menu: false,
  },
  {
    title: "Fare Structure",
    path: "/products/fare",
    component: FareStructure,
    main: "products",
    sub: "fare",
    order: 2,
    permission: "67",
    menu: true,
  },
  {
    title: "Add Station To Station",
    path: "/products/fare/add",
    component: StToStFare,
    main: "products",
    sub: "fare",
    order: 2,
    permission: "65",
    menu: false,
  },
  {
    title: "Edit Station To Station",
    path: "/products/fare/:id/edit",
    component: StToStFare,
    main: "products",
    sub: "fare",
    order: 2,
    permission: "66",
    menu: false,
  },
  {
    title: "General Settings",
    path: "/products/businessrules",
    component: BusinessRules,
    main: "Products",
    sub: "businessrules",
    order: 2,
    permission: "23", // ToDo: set permission
    menu: true,
  },
  {
    title: "Dashboard",
    path: "/dashboard/index",
    component: Dashboard,
    main: "dashboard",
    sub: "index",
    order: 1,
    permission: "15",
    menu: true,
  },
  {
    title: "Application inbox",
    path: "/partners/inbox",
    component: PartnerInbox,
    main: "Partners",
    sub: "inbox",
    order: 4,
    permission: "31",
    menu: true,
  },
  {
    title: "Loyalty",
    path: "/products/loyalty",
    component: Loyalty,
    main: "products",
    sub: "loyalty",
    order: 5,
    permission: "24",
    menu: true,
  },
  {
    title: "Loyalty Expiry",
    path: "/products/loyaltyexpiry",
    component: LoyaltyExpiry,
    main: "products",
    sub: "loyaltyexpiry",
    order: 6,
    permission: "24",
    menu: true,
  },
  {
    title: "Voucher",
    path: "/products/voucher",
    component: Vouchers,
    main: "products",
    sub: "voucher",
    order: 4,
    permission: "40",
    menu: true,
  },
  {
    title: "Organizations",
    path: "/users/organizations",
    component: Organizations,
    main: "Users",
    sub: "organizations",
    order: 6,
    permission: "16",
    menu: true,
  },
  {
    title: "Access Report",
    path: "/users/accessreport/",
    component: AccessReport,
    main: "Users",
    sub: "accessreport",
    order: 7,
    permission: "20",
    menu: true,
  },
  {
    title: "Loyalty Point Value",
    path: "/products/loyaltypointvalue/",
    component: LoyaltyPointValue,
    main: "products",
    sub: "loyaltypointvalue",
    order: 6,
    permission: "24",
    menu: true,
  },
  {
    path: "/logout",
    component: Logout,
    permission: "15",
    menu: false,
    exact: false,
  },
  {
    path: "/addVoucher",
    component: AddVoucher,
    permission: "41",
    menu: false,
    exact: false,
  },
  {
    path: "/addNewVoucherList",
    component: AddNewVoucher,
    permission: "41",
    menu: false,
    exact: false,
  },
  {
    path: "/uploadTemplate",
    component: UploadTemplate,
    permission: "41",
    menu: false,
    exact: false,

  },
  {
    path: "/products/loyalty/add",
    component: AddLoyaltyRule,
    permission: "51",
    menu: false,
    exact: false,
  },
  {
    path: "/products/loyalty/:id/edit",
    component: EditLoyaltyRule,
    permission: "52",
    menu: false,
    exact: false,
  },
  {
    path: "/products/loyaltyexpiry/:id/edit",
    component: EditLoyaltyExpiry,
    permission: "52",
    menu: false,
    exact: false,
  },
  {
    path: "/products/voucher/voucherDetail/:id",
    component: VoucherDetail,
    permission: "40",
    menu: false,
    exact: false,
  },
  {
    path: "/voucherBatch",
    component: VoucherBatches,
    permission: "40",
    menu: false,
    exact: false,
  },
  {
    path: "/addUser",
    component: AddUser,
    permission: "2",
    menu: false,
    exact: false,
  },
  {
    path: "/userDetail/:id",
    component: UserDetail,
    permission: "1",
    menu: false,
    exact: false,
  },
  // cust detail
  {
    path: "/customerDetail/:id",
    component: CustomerDetail,
    permission: "1",
    menu: false,
    exact: false,
  },
  {
    path: "/partners/application/:id",
    component: PartnerApplication,
    permission: "31",
    menu: false,
    exact: false,
  },
  {
    path: "/",
    component: Dashboard,
    permission: "10",
    menu: false,
    exact: false,
  },
  {
    path: "/print",
    component: PrintPreview,
    permission: "10",
    menu: false,
    exact: false,
  },
  {
    path: "/bo/print",
    component: PrintPreviewBo,
    permission: "10",
    menu: false,
    exact: false,
  },
  {
    title: "Voucher Checker",
    path: "/products/voucherchecker/",
    component: VoucherCodeInfo,
    main: "products",
    sub: "voucherchecker",
    order: 4,
    permission: "40",
    menu: true,
  },
  {
    title: "User Search",
    path: "/users/usersearch/",
    component: UserSearch,
    main: "Users",
    sub: "usersearch",
    order: 8,
    permission: "20",
    menu: true,
  },
  {
    title: "User Search",
    path: "/users/usersearch/:key",
    component: UserSearch,
    main: "Users",
    sub: "usersearch",
    order: 9,
    permission: "20",
    menu: false,
  },
  {
    title: "User Card",
    path: "/users/:id/card/:key",
    component: UserCard,
    main: "Users",
    sub: "usercard",
    order: 10,
    permission: "20",
    menu: false,
  },
  {
    title: "Add User Card",
    path: "/users/:id/card/:key/add",
    component: AddUserCard,
    main: "Users",
    sub: "addusercard",
    order: 11,
    permission: "20",
    menu: false,
  },
  {
    title: "Profile",
    path: "/profile",
    component: Profile,
    main: "Setting",
    permission: "15",
    menu: true,
    exact: false,
  },
  {
    title: "Redemption",
    path: "/users/redemption",
    component: Redemption,
    main: "Users",
    sub: "addusercard",
    order: 12,
    permission: "25",
    menu: true,
  },
  {
    title: "Ticket Activation",
    path: "/users/ticket/active",
    component: ActiveTicket,
    main: "Users",
    sub: "addusercard",
    order: 13,
    permission: "25",
    menu: true,
  },
  {
    title: "Incomplete Transaction Activation",
    path: "/users/ticket/incomplete-transaction-activation",
    component: ActivateTicketByOrderId,
    main: "Users",
    sub: "addusercard",
    order: 13,
    permission: "108",
    menu: true,
  },
  {
    title: "Ticket Activation History",
    path: "/users/ticket/activation-history",
    component: TicketActivationHistory,
    main: "Users",
    sub: "ticketactivationhistory",
    order: 5,
    permission: "25",
    menu: false,
  },
  {
    title: "Ticket Download",
    path: "/users/ticket/download",
    component: TicketDownload,
    main: "Users",
    sub: "ticketdownload",
    order: 14,
    permission: "81",
    menu: true,
  },
  {
    title: "Trigger Inbox Messages",
    path: "/users/send/messages",
    component: SendMessages,
    main: "Users",
    sub: "triggerinboxmsg",
    order: 15,
    permission: "82",
    menu: true,
  },
  {
    title: "Revenue",
    path: "/reports/revenue",
    component: Revenue,
    main: "report",
    sub: "revenue",
    order: 1,
    permission: "31",
    menu: true,
  },
  {
    title: "Instance Details",
    path: "/instance/:id/details/:referId",
    component: InstanceDetails,
    main: "inbox",
    sub: "details",
    order: 1,
    permission: "72",
    menu: false,
  },
  {
    title: "Instance",
    path: "/workflow/instances",
    component: Instances,
    main: "inbox",
    sub: "details",
    order: 1,
    permission: "72",
    menu: true,
  },
  {
    title: "Permission",
    path: "/workflow/permission",
    component: InstancePermission,
    main: "inbox",
    sub: "permission",
    order: 2,
    permission: "72",
    menu: true,
  },
  {
    title: "Transition",
    path: "/workflow/transition",
    component: TransitionPermission,
    main: "inbox",
    sub: "TransitionPermission",
    order: 3,
    permission: "72",
    menu: true,
  },
  {
    title: "IssueTicket",
    path: "/back-office/issue-ticket",
    component: IssueTicket,
    main: "backoffice",
    sub: "issue-ticket",
    order: 3,
    permission: "80",
    menu: true,
  },
  {
    title: "PurchaseTicket",
    path: "/back-office/purchaseTicket",
    component: PurchaseTicket,
    main: "backoffice",
    sub: "PurchaseTicket",
    order: 4,
    permission: "80",
    menu: false,
  },
  {
    title: "TicketSummary",
    path: "/back-office/ticketSummary",
    component: TicketSummary,
    main: "backoffice",
    sub: "TicketSummary",
    order: 5,
    permission: "80",
    menu: false,
  },
  {
    title: "Transaction",
    path: "/back-office/historyMaster",
    component: BoHistoryMaster,
    main: "backoffice",
    sub: "HistoryMaster",
    order: 5,
    permission: "80,86",
    menu: true,
  },
  {
    title: "History Details",
    path: "/back-office/historyMaster/:id/historyDetails",
    component: BoHistoryDetails,
    main: "backoffice",
    sub: "HistoryDetails",
    order: 5,
    permission: "80,86",
    menu: false,
  },
  {
    title: "Train Service Status",
    path: "/back-office/trainStatus",
    component: BoTrainStatus,
    main: "backoffice",
    sub: "TrainStatus",
    order: 6,
    permission: "84",
    menu: true,
  },
  {
    title: "Add Train Status",
    path: "/back-office/addTrainStatus",
    component: BoAddTrainStatus,
    main: "backoffice",
    sub: "AddTrainStatus",
    order: 6,
    permission: "84",
    menu: false,
  },
  {
    path: "/back-office/TrainStatus/:id/edit",
    component: BoEditTrainStatus,
    permission: "84",
    menu: false,
    exact: false,
  },
  {
    title: "Vouchers List",
    path: "/vouchers/voucherslist",
    component: VouchersList,
    main: "vouchers",
    sub: "vouchersList",
    order: 2,
    permission: "23",
    menu: true,
  },

    // {
    //   title: "Templates",
    //   path: "/vouchers/voucherstemplate",
    //   component: VouchersTemplate,
    //   main: "vouchers",
    //   sub: "templates",
    //   order: 3,
    //   permission: "23",
    //   menu: true,
    // } ,

    {

      title:"Approval",
      path: "/vouchers/vouchersapproval",
      component: VoucherApproval,
      main: "vouchers",
      sub: "templates",
      order: 4,
      permission: "23",
      menu: true,
    },

  {
    title: "Vouchers Details",
    path: "/vouchers/voucherdetails/:id",
    component: VouchersDetails,
    main: "vouchers",
    sub: "vouchersDetails",
    order: 3,
    permission: "23",
    menu: false,
  },
  {
    title:"ERL Voucher",
    path:"/vouchers/EditVoucher/:id",
    component:ErlVoucher,
    main:"vouchers",
    sub:"ErlVoucher",
    order:4,
    permission:"23",
    menu:false
  },
  {
    title:"Party Voucher",
    path:"/vouchers/EditPartyVoucher/:id",
    component:PartyVoucher,
    main:"vouchers",
    sub:"PartyVoucher",
    order:4,
    permission:"23",
    menu:false
  }
];
