import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  listData: null,
  listExport: null,
  error: null,
  loading: false,
  loadingExport: false,
  userType: { value: "Customer", label: "Customer" },
  org: { value: "1", label: "ERL" },
  sort_by: "u_fullName",
  sort_dir: "asc",
  searchKeyword: "",
  selectedRow: null,
  currentPage: 1,
  total: 0
};

const listCustomerExportStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loadingExport: true,
    listExport: null
  });
};

const listCustomerExportSuccess = (state, action) => {
  return updateObject(state, {
    listExport: action.list,
    loadingExport: false,
    error: null
  });
};

const listCustomerStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    listExport: null
  });
};

const listCustomerSuccess = (state, action) => {
  return updateObject(state, {
    listData: action.list,
    userType: action.filterOptions.userType,
    loading: false,
    org: action.filterOptions.org,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    selectedRow: null,
    currentPage: action.filterOptions.currentPage,
    total: action.total,
    error: null,
    loadingExport: false,
    listExport: null
  });
};

const listCustomerFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    userType: action.filterOptions.userType,
    org: action.filterOptions.org,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    currentPage: action.filterOptions.currentPage,
    listExport: null,
    error: action.error
  });
};

const selectCustomer = (state, action) => {
  return updateObject(state, {
    selectedRow: action.selectedRow //updateMultiSelect(state.selectedUsers, action.selectedUsers)
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_CUSTOMERS_SUCCESS:
      return listCustomerSuccess(state, action);
    case actionTypes.LIST_CUSTOMERS_FAIL:
      return listCustomerFail(state, action);
    case actionTypes.LIST_CUSTOMERS_START:
      return listCustomerStart(state, action);
    case actionTypes.LIST_CUSTOMERS_SELECT:
      return selectCustomer(state, action);
    case actionTypes.LIST_CUSTOMERS_EXPORT_START:
      return listCustomerExportStart(state, action);
    case actionTypes.LIST_CUSTOMERS_EXPORT_SUCCESS:
      return listCustomerExportSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
